import { lastValueFrom } from 'rxjs';

import { inject, Injectable, signal, WritableSignal } from '@angular/core';

import { AboControllerService } from '../../../../../shared/mockapi/abo/abo-controller.service';
import { AboDto } from '../../../../../shared/mockapi/abo/abo.dto';

@Injectable()
export class AboStateService {
    abo$: WritableSignal<AboDto | undefined> = signal(undefined);

    private aboControllerService: AboControllerService =
        inject(AboControllerService);

    async getAbo(): Promise<AboDto | undefined> {
        const aboDto: AboDto | undefined = await lastValueFrom(
            this.aboControllerService.getAbo()
        );
        console.log('aboDto', aboDto);
        this.abo$.set(aboDto);

        return aboDto;
    }
}
