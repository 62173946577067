import { Injectable } from '@angular/core';

import { AboDto } from './abo.dto';

@Injectable({ providedIn: 'root' })
export class AboService {
    abo: AboDto | undefined = { pages: 4, images: 100, pageSites: 10 };

    setAbo(abo: AboDto): AboDto {
        this.abo = abo;

        return this.abo;
    }
}
