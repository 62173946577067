import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MainComponent } from './main.component';
import { appRoutes } from './main.routes';
import { AboStateService } from './shared/services/abo-state/abo-state.service';
import { TopBarModule } from './top-bar/top-bar.module';

@NgModule({
    declarations: [MainComponent],
    exports: [MainComponent],
    imports: [
        CommonModule,
        ToastModule,
        RouterModule.forRoot(appRoutes, { bindToComponentInputs: true }),
        TopBarModule,
    ],
    providers: [AboStateService, MessageService],
})
export class MainModule {}
