import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';

import { environment } from '../../config/environments/environment';
import { ApiModule } from '../shared/openapi/api.module';
import { AppComponent } from './app.component';
import { MainModule } from './main/main.module';
import { FooterModule } from './main/shared/components/footer/footer.module';
import { PwLoginRegisterModule } from './pw-login-register/pw-login-register.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        MainModule,
        ApiModule.forRoot({ rootUrl: environment.rootUrl }),
        PwLoginRegisterModule,
        FooterModule,
    ],
    exports: [AppComponent],
    providers: [
        provideHttpClient(withInterceptors([authHttpInterceptorFn])),
        provideAuth0(environment.auth0.config),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
