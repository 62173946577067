import { Observable, of } from 'rxjs';

import { HttpContext } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { AboDto } from './abo.dto';
import { AboService } from './abo.service';

@Injectable({ providedIn: 'root' })
export class AboControllerService {
    aboService: AboService = inject(AboService);

    /**
     * GET abo
     * requestUrl: api/abo
     * response: AboDto
     */
    getAbo(): Observable<AboDto | undefined> {
        return of(this.aboService.abo);
    }

    /**
     * POST abo
     * requestUrl: api/abo
     * response: AboDto
     */
    postAbo(
        params: any,
        context?: HttpContext
    ): Observable<AboDto | undefined> {
        return of(this.aboService.setAbo(params.body));
    }
}
